import { Show, createEffect, createSignal } from "solid-js";
import { ContactInfoPage } from "./contact-info";
import { ExhibitionIdeaPage } from "./exhibition-idea";
import { LanguagePage } from "./language";
import { ResidencyPage } from "./residency";
import { Cmp, state } from ":mods";
import { ThankYouPage } from "../thank-you";
import { Mod } from ":mods";
import { StudentSignup } from "../../api";
import { SignupStudentsViewProps, StudentSignupProps } from "../../model";

export * from "./commitment";
export * from "./toc";

export function SignupFoundation({ $ }: SignupStudentsViewProps) {
  const signup_form_data = state.create({} as any);
  const [concatDone, setContactDone] = createSignal(false);
  const [stepNum, setStepNum] = createSignal(0);
  const [residencyDone, setResidencyDone] = createSignal(false);
  const [languageDone, setLanguageDone] = createSignal(false);
  const [exhibitionDone, setExhibitionDone] = createSignal(false);
  const [signupDone, setSignupDone] = createSignal(false);
  const [surveyDone, setSurveyDone] = createSignal(false);

  const handleContactInfo = async (values: StudentSignupProps) => {
    signup_form_data.set({ tc_agree: true, commitment_1: true, ...values });
    setStepNum(1);
    setContactDone(true);
  };
  const handleResidencyInfo = async (values) => {
    signup_form_data.set({ ...signup_form_data.value, ...values });
    setStepNum(2);
    setResidencyDone(true);
  };
  const handlLanguageInfo = async (values) => {
    signup_form_data.set({ ...signup_form_data.value, ...values });
    setStepNum(3);
    setLanguageDone(true);
  };
  const handlExhibitionInfo = async (values) => {
    signup_form_data.set({ ...signup_form_data.value, email: "bypass_error@error.no_domain", ...values });

    const payload = new FormData();
    Object.entries(signup_form_data.value).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        return;
      }
      if (value instanceof FileList) {
        if (value.length > 0) {
          payload.append(key, value[0]);
        }
      } else {
        if (key !== "exhibition_pdf") {
          payload.append(key, value as string);
        }
      }
    });
    console.log("payload ", signup_form_data.unwrap);
    // return;
    await StudentSignup(payload)
      .then((res) => {
        console.log("StudentSignup success :: ", res);
        $.actions.pushToast({
          type: "success",
          message: "Signup Completed Successfully!",
        });
        if (!!res) {
          setExhibitionDone(true);
          setSignupDone(true);
          setStepNum(undefined);
        }
      })
      .catch((error) => {
        // props.$.actions.pushToast({
        //   type: "error",
        //   message: "There is an issue during form submission",
        // });
        Cmp.Alert.actions.pushToast({
          type: "error",
          message: error,
        });
      });
  };

  const handleSurveySubmit = (values) => {
    console.log(values);
    setSurveyDone(true);
  };

  createEffect(() => {
    console.log("update signup form data  :: ", signup_form_data.value);
  });
  return (
    <>
      {/* <Show when={false}>
        <Mod.Surveys.SignupSurvey onFormSubmit={handleSurveySubmit} />
      </Show> */}
      <Show when={stepNum() !== undefined} fallback={<ThankYouPage />}>
        <ContactInfoPage
          $={$}
          class={`${stepNum() === 0 ? "" : "!hidden"}`}
          onFormSubmit={handleContactInfo}
          onBack={undefined}
        />
        <ResidencyPage
          $={$}
          class={`${stepNum() === 1 ? "" : "!hidden"}`}
          onFormSubmit={handleResidencyInfo}
          onBack={() => setStepNum(0)}
        />
        <LanguagePage
          $={$}
          class={`${stepNum() === 2 ? "" : "!hidden"}`}
          onFormSubmit={handlLanguageInfo}
          onBack={() => setStepNum(1)}
        />
        <ExhibitionIdeaPage
          $={$}
          class={`${stepNum() === 3 ? "" : "!hidden"}`}
          onFormSubmit={handlExhibitionInfo}
          onBack={() => setStepNum(2)}
        />
      </Show>
    </>
  );
}
